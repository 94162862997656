import { useParams, useLocation, Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from '../../hooks/useFirestore'
import { projectFirestore } from '../../firebase/config'
import firebase from 'firebase/app'
import "firebase/storage"
import { useEffect, useState } from 'react'

import Parser from 'html-react-parser/dist/html-react-parser'

// "import": {
//     "types": "./dist/index.d.ts",
//     "default": "./dist/index.mjs"
//   },

//email
//import  {Resend}  from 'resend';
// const resend = new Resend("re_XZrf7Uoo_Fqs5sUkvakuSrAxinB3p8Vbe");
// const slkdfj = require("resend")

// styles
import styles from './EditTrip.module.css'

export default function EditTrip({props}) {
    const { user } = useAuthContext()
    const {id} = useParams();
    const {state} = useLocation();
    const { addTrip, response } = useFirestore('trips')
    const history = useHistory()
    const [trip, setTrip] = useState(null)
    const [tripInformation, setTripInformation] = useState(null)
    const [user_, setUser_] = useState(null)

    useEffect(() => {
        const tripRef = projectFirestore.collection("trips").doc(id)
        tripRef.get().then((querySnapshot) => {
            if(querySnapshot.exists){
                console.log("trip found")
                let trip_data = querySnapshot.data()
                setTrip(trip_data)
                setType(trip_data.type)
                setCountry(trip_data.country)
                setPlace(trip_data.place)
                setPrice(trip_data.price)
                setMinParticipants(trip_data.minParticipants)
                setMaxParticipants(trip_data.maxParticipants)
                setSubscribeDeadline(trip_data.subscribeDeadline)
                setDescription(trip_data.description)
                setDateStart(trip_data.dateStart)
                setDateEnd(trip_data.dateEnd)
                setImage(trip_data.imageUrl)
            }
            else{
                console.log("trip not found")
            }
        })

        const tripInformationRef = projectFirestore.collection("trips").doc(id).collection("information")
        tripInformationRef.get().then((querySnapshot) => {
            querySnapshot.forEach((item) => {
                if(item.exists){
                    let trip_information_data = item.data()
                    console.log({...trip_information_data,id:item.id})
                    setTripInformation({...trip_information_data,id:item.id})
                    if(trip_information_data.extra_information.content){
                        setContent(trip_information_data.extra_information.content)
                    }
                    if(trip_information_data.extra_information.preferences){
                        setPreferences(trip_information_data.extra_information.preferences)
                    }
                    if(trip_information_data.extra_information.options){
                        setOptions(trip_information_data.extra_information.options)
                    }
                    if(trip_information_data.extra_information.optionsPriceValues){
                        setOptionsPriceValue(trip_information_data.extra_information.optionsPriceValues)
                    }
                    
                    
                }
                else{
                    console.log("no extra info")
                }
            })
        })

        const userRef = projectFirestore.collection("users").doc(user.uid)
        userRef.get().then((item) => {
            if(item.exists){
                setUser_(item.data())
            }
            
        })
    },[])
    
    // params
    const [type,setType] = useState("")
    const [country,setCountry] = useState("")
    const [place, setPlace] = useState("")
    const [price, setPrice] = useState("")
    const [minParticipants, setMinParticipants] = useState("")
    const [maxParticipants, setMaxParticipants] = useState("")
    const [subscribeDeadline, setSubscribeDeadline] = useState("")
    const [description, setDescription] = useState("")
    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("")
    const [image, setImage] = useState(null)
    const [imageLarge, setImageLarge] = useState(null)
    const [contentTitles, setContentTitles] = useState([""])
    const [content, setContent] = useState(null)
    const [questions,setQuestions] = useState([""]);
    const [questionsOptions,setQuestionsOptions] = useState([""]);

    

    // input params
    const [preferences, setPreferences] = useState([]);
    const [options, setOptions] = useState([]);
    const [optionsPriceValues, setOptionsPriceValue] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()
        // upload image
        console.log("uploading file...")
        var storageRef = firebase.storage().ref();
        var imgRef = storageRef.child("trips/"+image.name);
        console.log(imgRef)
        

        imgRef.put(image).then((snapshot) => {
            console.log(snapshot)
            imgRef.getDownloadURL().then((imageUrl) => {
                console.log(imageUrl)
                console.log(contentTitles)
                addTrip({
                    type, 
                    country,
                    place,
                    price,
                    description,
                    maxParticipants,
                    minParticipants,
                    subscribeDeadline,
                    currentSubscriptions: 0,
                    participants: firebase.firestore.FieldValue.arrayUnion(),
                    dateStart,
                    dateEnd,
                    imageUrl,
                    isVisible: true
                    
                  }, 
                  {
                    content,
                    preferences,
                    options,
                    optionsPriceValues
                  }).then((id) => {
                    if(id){
                        // uploading large image
                        console.log("uploading file...")
                        var imgRefLarge = storageRef.child("trips/"+"large_"+image.name);
                        console.log(imgRefLarge)
                        imgRefLarge.put(imageLarge).then((snapshot) => {
                            console.log(snapshot)
                            imgRefLarge.getDownloadURL().then((imageUrl) => {
                                // console.log(imageUrl)
                                let tripRef = projectFirestore.collection("trips").doc(id).update({largeImageUrl: imageUrl})
                            })
                        })
                    }
                  }).then(()=>console.log("trip uploaded!"))
            })
        });

        
    } 

    const handleUpdateTrip = () => {
        console.log("updating")
        console.log("updating trip with information id: ", tripInformation.id)
        let tripRef = projectFirestore.collection("trips").doc(id).update({type,country,place,price,minParticipants,maxParticipants,subscribeDeadline,description,dateStart,dateEnd})
        let tripInformationRef = projectFirestore.collection("trips").doc(id).collection("information").doc(tripInformation.id).update({extra_information:{content, preferences,options,optionsPriceValues}})
    }

    // reset the form fields    
    useEffect(() => {
        console.log(response)
        if (response.success) {
            // go back to trips
            history.push("/trips")
        }
    }, [response.success])

    useEffect(() => {
        console.log(response)
    }, [response])
    

    const handleFileChange = () => {
        
    }

    const handleExtraInfoField = (fieldFunction) => {
        fieldFunction(_tmp => [..._tmp, ""]);
    }

    return (
        user_ && 
            <>
            {user_.role == "Admin" && <div className={styles.container}>
            <h1>New trip</h1>
            <h2>Basic information</h2>
            <form>
                <label>
                <span>Type:</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setType(e.target.value)} 
                    value={type} 
                />
                </label>
               
                <label>
                <span>Country:</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setCountry(e.target.value)} 
                    value={country} 
                />
                </label>

                <label>
                <span>Place:</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setPlace(e.target.value)} 
                    value={place} 
                />
                </label>

                <label>
                <span>Price:</span>
                <input 
                    type="number"
                    required
                    onChange={(e) => setPrice(e.target.value)} 
                    value={price} 
                />
                </label>

                <label>
                <span>Starting date:</span>
                <input 
                    type="date"
                    required
                    onChange={(e) => setDateStart(e.target.value)} 
                    value={dateStart} 
                />
                </label>

                <label>
                <span>Ending date:</span>
                <input 
                    type="date"
                    required
                    onChange={(e) => setDateEnd(e.target.value)} 
                    value={dateEnd} 
                />
                </label>

                <label>
                <span>Short description:</span>
                <textarea 
                    type="text"
                    required
                    onChange={(e) => setDescription(e.target.value)} 
                    value={description} 
                />
                </label>

                <label>
                <span>Maximum participants:</span>
                <input 
                    type="number"
                    required
                    onChange={(e) => setMaxParticipants(e.target.value)} 
                    value={maxParticipants} 
                />
                </label>
                <label>
                <span>Minimum participants:</span>
                <input 
                    type="number"
                    required
                    onChange={(e) => setMinParticipants(e.target.value)} 
                    value={minParticipants} 
                />
                </label>
                <label>
                <span>Subscribing deadline:</span>
                <input 
                    type="date"
                    required
                    onChange={(e) => setSubscribeDeadline(e.target.value)} 
                    value={subscribeDeadline} 
                />
                </label>
                {/* <label>
                    <span>Thumbnail image</span>
                    <input
                        required
                        type="file"
                        onChange={(e) => setImage(e.target.files[0])}
                    ></input>
                </label> */}
                {image && <img src={image}></img>}
                </form >
                <h2>Extra information</h2>
                
                {/* <label>
                    <span>Thumbnail image large</span>
                    <input
                        required
                        type="file"
                        onChange={(e) => setImageLarge(e.target.files[0])}
                    ></input>
                </label> */}
                <textarea 
                    type="text"
                    required
                    onChange={(e) => setContent(e.target.value)}
                    value={content} 
                    style={{width:"100%"}}
                />
                {content && <div>
                    {Parser(content)}
                </div>}
                
                <h2>Preferences for subscriber</h2>
                <button onClick={()=> setPreferences(_tmp => [..._tmp, ""])} className='btn'>Add preference option field</button><br></br>
                {preferences.map((pref, index) => (
                <>
                <span>Preference {index +1}</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setPreferences(preferences.map((item, index_) =>{
                        if(index==index_){
                            item = e.target.value
                        }
                        return item
                    }))}
                    value={preferences[index]} 
                />
                </>
                
                ))}

                <h2>Options for subscriber</h2>
                <button onClick={()=> {
                    setOptions(_tmp => [..._tmp, ""])
                    setOptionsPriceValue(_tmp => [..._tmp, 0])
                }
                    } className='btn'>Add option field</button><br></br>
                {options.map((pref, index) => (
                <>
                <span>Option {index +1}</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setOptions(options.map((item, index_) =>{
                        if(index==index_){
                            item = e.target.value
                        }
                        return item
                    }))}
                    value={options[index]} 
                />
                <span>Price</span>
                <input 
                    type="number"
                    required
                    onChange={(e) => setOptionsPriceValue(optionsPriceValues.map((item, index_) =>{
                        if(index==index_){
                            item = e.target.value
                        }
                        return item
                    }))}
                    value={optionsPriceValues[index]} 
                />
                </>))}
            
            <button className="btn" onClick={() => handleUpdateTrip()}>Update trip</button>
        </div>
        }
        {user_.role!="Admin" && <p>User is not authorised</p>}
        </>
    )
}