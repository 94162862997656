import { Link } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import { useLogout } from '../hooks/useLogout'

// styles
import styles from './Navbar.module.css'

import logo from "../assets/logo.png"
import icon_home from "../assets/icon_home.svg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

import { NavbarContext } from "../context/NavbarContext"
import { useContext } from "react"

export default function Footer() {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const history = useHistory();
  const {navbarDarkMode, toggleNavbarDarkMode} = useContext(NavbarContext)

  return (
    navbarDarkMode && 
    <footer className={styles.footer}>
      <p style={{textAlign:"center" ,marginRight: '50px'}}>© Lets Go Tripping</p>
      <p>Contact: <a style={{color:"black", textDecoration:"none"}} href="mailto:info@letsgotripping.be">info@letsgotripping.be</a></p>
        
    </footer>
  
  )
}
