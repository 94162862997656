import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDkYMebPbm-bGTnu50AOairkjLoKa3ffEI",
  authDomain: "lets-go-tripping.firebaseapp.com",
  projectId: "lets-go-tripping",
  storageBucket: "lets-go-tripping.appspot.com",
  messagingSenderId: "697056515863",
  appId: "1:697056515863:web:e1440d361a6e9aacfa8952"
}

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
// timestamp
const timestamp = firebase.firestore.Timestamp
const provider = new firebase.auth.GoogleAuthProvider();


export { projectFirestore, projectAuth, timestamp, provider}