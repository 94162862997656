import { useParams, useLocation, Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from '../../hooks/useFirestore'
import { projectFirestore } from '../../firebase/config'
import firebase from 'firebase/app'
import "firebase/storage"
import { useEffect, useState } from 'react'

// "import": {
//     "types": "./dist/index.d.ts",
//     "default": "./dist/index.mjs"
//   },

//email
//import  {Resend}  from 'resend';
// const resend = new Resend("re_XZrf7Uoo_Fqs5sUkvakuSrAxinB3p8Vbe");
// const slkdfj = require("resend")

// styles
import styles from './NewTrip.module.css'

export default function NewTrip({props}) {
    const { user } = useAuthContext()
    const {id} = useParams();
    const {state} = useLocation();
    const { addTrip, response } = useFirestore('trips')
    const history = useHistory()
    
    // params
    const [type,setType] = useState("")
    const [country,setCountry] = useState("")
    const [place, setPlace] = useState("")
    const [price, setPrice] = useState("")
    const [minParticipants, setMinParticipants] = useState("")
    const [maxParticipants, setMaxParticipants] = useState("")
    const [subscribeDeadline, setSubscribeDeadline] = useState("")
    const [description, setDescription] = useState("")
    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("")
    const [image, setImage] = useState(null)
    const [imageLarge, setImageLarge] = useState(null)
    const [contentTitles, setContentTitles] = useState([""])
    const [content, setContent] = useState(null)
    const [questions,setQuestions] = useState([""]);
    const [questionsOptions,setQuestionsOptions] = useState([""]);

    // input params
    const [preferences, setPreferences] = useState([]);
    const [options, setOptions] = useState([]);
    const [optionsPriceValues, setOptionsPriceValue] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault()
        // upload image
        console.log("uploading file...")
        var storageRef = firebase.storage().ref();
        var imgRef = storageRef.child("trips/"+image.name);
        console.log(imgRef)
        

        imgRef.put(image).then((snapshot) => {
            console.log(snapshot)
            imgRef.getDownloadURL().then((imageUrl) => {
                console.log(imageUrl)
                console.log(contentTitles)
                addTrip({
                    type, 
                    country,
                    place,
                    price,
                    description,
                    maxParticipants,
                    minParticipants,
                    subscribeDeadline,
                    currentSubscriptions: 0,
                    participants: firebase.firestore.FieldValue.arrayUnion(),
                    dateStart,
                    dateEnd,
                    imageUrl,
                    isVisible: true
                    
                  }, 
                  {
                    content,
                    preferences,
                    options,
                    optionsPriceValues
                  }).then((id) => {
                    if(id){
                        // uploading large image
                        console.log("uploading file...")
                        var imgRefLarge = storageRef.child("trips/"+"large_"+image.name);
                        console.log(imgRefLarge)
                        imgRefLarge.put(imageLarge).then((snapshot) => {
                            console.log(snapshot)
                            imgRefLarge.getDownloadURL().then((imageUrl) => {
                                // console.log(imageUrl)
                                let tripRef = projectFirestore.collection("trips").doc(id).update({largeImageUrl: imageUrl})
                            })
                        })
                    }
                  }).then(()=>console.log("trip uploaded!"))
            })
        });

        
    } 

    // reset the form fields
    useEffect(() => {
        console.log(response)
        if (response.success) {
            // go back to trips
            history.push("/trips")
        }
    }, [response.success])

    useEffect(() => {
        console.log(response)
    }, [response])
    

    const handleFileChange = () => {
        
    }

    const handleExtraInfoField = (fieldFunction) => {
        fieldFunction(_tmp => [..._tmp, ""]);
    }

    const sendEmail = async () => {
        
        // const { data, error } = await resend.emails.send({
        //     from: 'Acme <onboarding@resend.dev>',
        //     to: ['delivered@resend.dev'],
        //     subject: 'Hello World',
        //     html: '<strong>It works!</strong>',
        //   });
        
        //   if (error) {
        //     return res.status(400).json(error);
        //   }
        
        //   res.status(200).json(data);
    }
    
    return (
        user && <div className={styles.container}>
            <h1>New trip</h1>
            <h2>Basic information</h2>
            <form onSubmit={handleSubmit}>
                <label>
                <span>Type:</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setType(e.target.value)} 
                    value={type} 
                />
                </label>
               
                <label>
                <span>Country:</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setCountry(e.target.value)} 
                    value={country} 
                />
                </label>

                <label>
                <span>Place:</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setPlace(e.target.value)} 
                    value={place} 
                />
                </label>

                <label>
                <span>Price:</span>
                <input 
                    type="number"
                    required
                    onChange={(e) => setPrice(e.target.value)} 
                    value={price} 
                />
                </label>

                <label>
                <span>Starting date:</span>
                <input 
                    type="date"
                    required
                    onChange={(e) => setDateStart(e.target.value)} 
                    value={dateStart} 
                />
                </label>

                <label>
                <span>Ending date:</span>
                <input 
                    type="date"
                    required
                    onChange={(e) => setDateEnd(e.target.value)} 
                    value={dateEnd} 
                />
                </label>

                <label>
                <span>Short description:</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setDescription(e.target.value)} 
                    value={description} 
                />
                </label>

                <label>
                <span>Maximum participants:</span>
                <input 
                    type="number"
                    required
                    onChange={(e) => setMaxParticipants(e.target.value)} 
                    value={maxParticipants} 
                />
                </label>
                <label>
                <span>Minimum participants:</span>
                <input 
                    type="number"
                    required
                    onChange={(e) => setMinParticipants(e.target.value)} 
                    value={minParticipants} 
                />
                </label>
                <label>
                <span>Subscribing deadline:</span>
                <input 
                    type="date"
                    required
                    onChange={(e) => setSubscribeDeadline(e.target.value)} 
                    value={subscribeDeadline} 
                />
                </label>
                <label>
                    <span>Thumbnail image</span>
                    <input
                        required
                        type="file"
                        onChange={(e) => setImage(e.target.files[0])}
                    ></input>
                </label>
                </form >
                <h2>Extra information</h2>
                <form onSubmit={handleSubmit}>
                <label>
                    <span>Thumbnail image large</span>
                    <input
                        required
                        type="file"
                        onChange={(e) => setImageLarge(e.target.files[0])}
                    ></input>
                </label>
                <textarea 
                    type="text"
                    required
                    onChange={(e) => setContent(e.target.value)}
                    value={content} 
                />
                {/* {content.map((trip, index) => (
                <label>
                <span>Subtitle {index+1}</span>
                <input 
                    type="text"
                    required
                    onChange={(e) => setContentTitles(contentTitles.map((item, index_) =>{
                        if(index==index_){
                            item = e.target.value
                        }
                        return item
                    }))}
                    value={contentTitles[index]} 
                />
                <textarea 
                    type="text"
                    required
                    onChange={(e) => setContent(content.map((item, index_) =>{
                        if(index==index_){
                            item = e.target.value
                        }
                        return item
                    }))}
                    value={content[index]} 
                />
                
                </label>
                
                ))
                } */}
                {/* <button className='btn' onClick={handleExtraInfoField}>Add extra infofield</button> */}
                <br></br>
                <br></br>
                <button className='btn'>Upload</button>
            </form>
            <h2>Preferences for subscriber</h2>
            <button onClick={()=> setPreferences(_tmp => [..._tmp, ""])} className='btn'>Add preference option field</button><br></br>
            {preferences.map((pref, index) => (
            <>
            <span>Preference {index +1}</span>
            <input 
                type="text"
                required
                onChange={(e) => setPreferences(preferences.map((item, index_) =>{
                    if(index==index_){
                        item = e.target.value
                    }
                    return item
                }))}
                value={preferences[index]} 
            />
            </>
            
            ))}

            <h2>Options for subscriber</h2>
            <button onClick={()=> {
                setOptions(_tmp => [..._tmp, ""])
                setOptionsPriceValue(_tmp => [..._tmp, 0])
            }
                } className='btn'>Add option field</button><br></br>
            {options.map((pref, index) => (
            <>
            <span>Option {index +1}</span>
            <input 
                type="text"
                required
                onChange={(e) => setOptions(options.map((item, index_) =>{
                    if(index==index_){
                        item = e.target.value
                    }
                    return item
                }))}
                value={options[index]} 
            />
            <span>Price</span>
            <input 
                type="number"
                required
                onChange={(e) => setOptionsPriceValue(optionsPriceValues.map((item, index_) =>{
                    if(index==index_){
                        item = e.target.value
                    }
                    return item
                }))}
                value={optionsPriceValues[index]} 
            />
            </>
            
            ))}
            
        </div>
    )
}