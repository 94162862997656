import { useState, useEffect } from 'react'
import { projectAuth, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (email, password, firstName, lastName) => {
    setError(null)
    setIsPending(true)
  
    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }

      // add display name to user
      //await res.user.updateProfile({ displayName: firstName })

      // add user to db
      projectFirestore.collection("users").doc(res.user.uid).set({
        firstName: firstName,
        lastName: lastName,
        role: "User",
        email: email
      })
      .then(() => {
        console.log("Document successfully written!");
        dispatch({ type: 'LOGIN', payload: res.user })
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

      // dispatch login action

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}