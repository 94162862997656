import { useEffect, useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'

// styles
import styles from './Home.module.css'

// components
import TransactionForm from './TransactionForm'
import TransactionList from './TransactionList'
import { projectFirestore } from '../../firebase/config'
import TripsList from '../trips/TripsList'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function Home() {
  const { user } = useAuthContext()
  const [myTrips, setMyTrips] = useState([])
  const [role, setRole] = useState("");
  const history = useHistory();
  const [name, setName] = useState("")
  const [users,setUsers] = useState([])
  const [trips,setTrips] = useState([])

  const [tempi, setTempi] = useState(0)

  useEffect(() => {
    projectFirestore.collection("users").doc(user.uid).collection("subscriptions").get()
    .then((querySnapshot) => {
      let myTripsTemp = [];
      querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //myTripsTemp.push(doc.data().tripId)
          projectFirestore.collection("trips").doc(doc.data().tripId).get().then((item)=>{
            if(item.exists){
              // console.log(item.data())
              // myTripsTemp.push({...item.data(), id: item.id})
              setMyTrips(_tmp => [..._tmp, {...item.data(), id: item.id}])
              // myTripsTemp.push({...item.data(), id: item.id})
              // console.log(item.data())
              // setMyTrips(_tmp => [..._tmp, { id: item.id}])
            }
            else{
              console.log("whoops, this trip doesn't exist")
            }      
          })
        })
        //setMyTrips(_tmp => [..._tmp, ...myTripsTemp])
      })
    },[])
    
    useEffect(() => {
    let userRef = projectFirestore.collection("users").doc(user.uid)
    userRef.onSnapshot((user) => {
      setRole(user.data().role)
      setName(user.data().firstName)
      if(user.data().role == "Admin"){
        console.log("user is admin")

        let usersRef = projectFirestore.collection("users")
        usersRef.get().then((querySnapshot) => 
        querySnapshot.forEach((item) => {
          if(item.exists){
            // get subscriptions
            let userSubRef = projectFirestore.collection("users").doc(item.id).collection("subscriptions")
            userSubRef.get().then((querySnapshot_) => {
              let subscriptions = []
              querySnapshot_.forEach((item_) => {
                if(item_.exists){
                  subscriptions.push(item_.data())
                }
              } )
              setUsers(_tmp => [..._tmp, {...item.data(),uid:item.id, trips:subscriptions}]);
            })
          }
        })
      )}
      const allTripsRef = projectFirestore.collection("trips")
      allTripsRef.get().then((querySnapshot) =>{
        querySnapshot.forEach((trip) => {
          setTrips(_tmp => [..._tmp, {...trip.data(),tripId: trip.id}]);
        })
      })


    })
    },[])

  return (
    <div className={styles.container}>
      <div className={styles.home_wrapper}>
      <h1>Home</h1>
      {user && <p>Hallo {name}!</p>}
      {role == "Admin" &&  
      <>
        <p>Role: {role}</p>
        <button className='btn' onClick={()=>{history.push("/new-trip")}}>Add new trip</button>
        <h2>Gebruikers</h2>
        <div style={{display:"grid",gridTemplateColumns:"auto auto auto auto"}}>
        <p><b>Uid</b></p>
        <p><b>Voornaam</b></p>
        <p><b>Achternaam</b></p>
        <p><b>Trips</b></p>
        {users && users.map((item) => (
          <>
            <p>{item.uid}</p>
            <p>{item.firstName}</p>
            <p>{item.lastName}</p>
            <p>{item.trips.length}</p>
  
            {/* <div>
            <ul>
              {item.trips && item.trips.map((item_ => (
                <li>Trip: {item_.tripId}</li>
              )))}
            </ul>
            </div> */}
          </>
        ))}
        </div>
        <h2>Trips</h2>
        <div style={{display:"grid",gridTemplateColumns:"auto auto auto auto auto auto auto"}}>
        <p><b>Trip id</b></p>
        <p><b>Type</b></p>
        <p><b>Land</b></p>
        <p><b>Min inschrijvingen</b></p>
        <p><b>Totaal inschrijvingen</b></p>
        <p><b>Max inschrijvingen</b></p>
        <p><b>Acties</b></p>
        {trips && trips.map((trip) => (
          <>
            <p>{trip.tripId}</p>
            <p>{trip.type}</p>
            <p>{trip.country}</p>
            <p>{trip.minParticipants}</p>
            <p>{trip.currentSubscriptions}</p>
            <p>{trip.maxParticipants}</p>
            <button style={{background:'none', border:"none", cursor:"pointer"}} onClick={() => history.push({pathname: '/edit-trip/'+trip.tripId})}>Edit</button>
          </>
        ))}
        </div>
      </>
      }
      <h2>Jouw trips</h2>
      <div className={styles.trips_container}>
      {myTrips.length>0 && <TripsList trips={myTrips}></TripsList>}
      {!myTrips.length>0 && <p style={{alignSelf:"start", width:"100%"}}>Er konden geen trips gevonden worden.</p>}
      </div>
    </div>
    </div>
  )
}
