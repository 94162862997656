import { useParams, useLocation, Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from '../../hooks/useFirestore'
import { projectFirestore } from '../../firebase/config'
import firebase from 'firebase/app'
import { useEffect, usetrip_overview, useState } from 'react'
import styles from './Trip.module.css'

// test google auth
import {provider} from "../../firebase/config.js"

import Parser from 'html-react-parser/dist/html-react-parser'

import icon_person_white from "../../assets/icon_person_white.svg"
import icon_person_green from "../../assets/icon_person_green.svg"
import { TailSpin } from 'react-loader-spinner'

export default function Trip({props}) {
    const { user } = useAuthContext()
    const {id} = useParams();
    const {trip_overview} = useLocation();
    const history = useHistory();

    const [showSubmitForm, setShowSubmitForm] = useState(false)

    // information 
    const [tripBasicInformation, setTripBasicInformation] = useState(null)
    const [tripDetailsInformation, setTripDetailsInformation] = useState(null)
    const [tripSubscriptions, setTripSubscriptions] = useState(null)
    const [tripOrganisers, setTripOrganisers] = useState(null)
    const [userData, setUserData] = useState(null)
    const [isUserSubscribed, setIsUserSubscribed] = useState(false)
    const [isTripFull, setIsTripFull] = useState(false)
    const [isTripInPast, setIsTripInPast] = useState(false)
    const [isTripCancelled,setIsTripCancelled] = useState(false)

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    // input params
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [adress, setAdress] = useState("");
    const [preferencesOptions, setPreferencesOptions] = useState([]);
    const [preferences_, setPreferences_] = useState([])   
    const [preferences, setPreferences] = useState([]);
    const [options, setOptions] = useState([]);
    const [options_, setOptions_] = useState([])
    const [priceOptions, setPriceOptions] = useState([])
    const [priceTrip, setPriceTrip] = useState(0);
    const [priceVar, setPriceVar] = useState(0)
    const [extraUserInput, setExtraUserInput] = useState("")

    const handleSubscribe = (e) => {
        console.log("hande subscribe")
        e.preventDefault()
        //do final check if maxParticipants not exceded
        var tripSubscriptionRef = projectFirestore.collection("trips").doc(id).collection("subscriptions")
        // change totalToPay based on trip and choices
        var trips = tripSubscriptionRef.add({uid: user.uid, name: user.displayName, payedAmount:0,totalToPay:priceVar, preferences: preferences_, options:options_,totalPayed:0, extraUserInput: extraUserInput, phoneNumer:phoneNumber, addres:adress})
        var tripRef = projectFirestore.collection("trips").doc(id)
        tripRef.update({currentSubscriptions: tripBasicInformation.currentSubscriptions+1})

        var userRef = projectFirestore.collection("users").doc(user.uid).collection("subscriptions")
        userRef.add({tripId: id})
        setShowSubmitForm(true)
        history.push({pathname:'/home'})
    }

    // Load data
    useEffect(() => {
        var tripRef = projectFirestore.collection("trips").doc(id);
        tripRef.onSnapshot((trip) => {
            if(trip.exists){
                setTripBasicInformation({...trip.data()})
                setPriceTrip(parseInt(trip.data().price))
                setPriceVar(parseInt(trip.data().price))
                if(trip.data().currentSubscriptions == trip.data().maxParticipants){
                    setIsTripFull(true)
                }
                setIsTripInPast(checkIfTripInPast(trip.data()))
                if(checkIfDateInPast(trip.data().subscribeDeadline) && trip.data().currentSubscriptions<trip.data().minParticipants && !checkIfTripInPast(trip.data())){
                    setIsTripCancelled(true)
                }
            }
            else{
                setTripBasicInformation("Not found")
                setIsImageLoaded(true);
            }
            //if trip does not exists
            
        })
        var informationRef = projectFirestore.collection("trips").doc(id).collection("information");
            informationRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    // setContentTitles([doc.data().extra_information.contentTitles])
                    // setContent([doc.data().extra_information.content])
                    setTripDetailsInformation(doc.data().extra_information.content)
                    setPreferencesOptions(doc.data().extra_information.preferences)
                    setOptions(doc.data().extra_information.options)
                    setPriceOptions(doc.data().extra_information.optionsPriceValues)
                    
                    // if(doc.data().extra_information.preferences){
                    //     preferences_ = Array(doc.data().extra_information.preferences.length)
                    // }
                    // if(doc.data().extra_information.options){
                    //     preferences_ = Array(doc.data().extra_information.preferences.length)
                    // }
                });
        });
        var subscriptionsRef = projectFirestore.collection("trips").doc(id).collection("subscriptions");
        subscriptionsRef.get().then((querySnapshot) => {
            let subscriptionsTemp = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // setContentTitles([doc.data().extra_information.contentTitles])
                // setContent([doc.data().extra_information.content])
                subscriptionsTemp.push(doc.data())
                
            })
            setTripSubscriptions(subscriptionsTemp)
        ;});

        var organisersRef = projectFirestore.collection("trips").doc(id).collection("organisers");
        organisersRef.get().then((querySnapshot) => {
            let organisersTemp = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // setContentTitles([doc.data().extra_information.contentTitles])
                // setContent([doc.data().extra_information.content])
                organisersTemp.push(doc.data())
                
            })
            setTripOrganisers(organisersTemp)
        ;});
        
        if(user){
            var userRef = projectFirestore.collection("users").doc(user.uid);
            userRef.get().then((doc) => {
                setUserData(doc.data())
                var userSubRef = projectFirestore.collection("users").doc(user.uid).collection("subscriptions");
                userSubRef.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if(doc.data().tripId == id){
                            setIsUserSubscribed(true)
                        }
                    })})
            ;});
        }        
        },[])

        const getDayMonth = (string_date) => {
            const date = new Date(string_date);
            return date.getDate()+"/"+(date.getMonth()+1)
          }
        
          const getYear = (string_date) => {
            const date = new Date(string_date);
            return date.getFullYear();
          }
          const getDateFormated = (string_date) => {
            const date = new Date(string_date);
            return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
          }

          useEffect(() => {
            const handleEsc = (event) => {
               if (event.key === 'Escape') {
                setShowSubmitForm(false)
              }
            };
            window.addEventListener('keydown', handleEsc);
        
            return () => {
              window.removeEventListener('keydown', handleEsc);
            };
          }, []);
        
          const handleChange = (event) => {
            // 👇️ this is the checkbox itself
            
            // 👇️ this is the checked value of the field
            

            let old_preferences = preferences_

            if(event.target.checked){
                old_preferences.push(event.target.value)
            }
            else{
                old_preferences = old_preferences.filter(e => e !== event.target.value); // will return ['A', 'C']
            }
            setPreferences_(old_preferences)
            
          };

          const handleChangeOptions = (event) => {
            // 👇️ this is the checkbox itself
            // console.log(event.target);
            // 👇️ this is the checked value of the field
            // console.log(event.target.checked);
            
            let old_options= options_

            if(event.target.checked){
                old_options.push(event.target.value)
                
            }
            else{
                old_options = old_options.filter(e => e !== event.target.value); // will return ['A', 'C']
            }
            setOptions_(old_options)
            // console.log(old_options)
            calculatePrice(old_options)
          };

          const calculatePrice = (optionArray) => {
            // console.log("price options: ",priceOptions)

            let price = priceTrip
            // console.log("old price :",price)
            optionArray.map((item,index) => {
                let i = options.indexOf(item)
                price = price + parseInt(priceOptions[i])
            })
            setPriceVar(price)
            // console.log("new price :",price)
          }

          const checkIfTripInPast = (trip) => {
            const trip_time = new Date(trip.dateStart).getTime();
            if(trip_time>Date.now()){
              return false;
            }
            else {
              return true;
            }
          }

          const checkIfDateInPast = (date) => {
            const date_time = new Date(date).getTime();
            if(date_time>Date.now()){
              return false;
            }
            else {
              return true;
            }
          }

    return (
        <div className={styles.trip_wrapper}>
            <div className={styles.trip_container}>
            {!isImageLoaded && 
            <div style={{marginTop:"200px", height: "100%"}}>
                <TailSpin
                                height="80"
                                width="80"
                                color="#000000"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{justifyContent:"center"}}
                                wrapperClass=""
                                visible={true}
                            />
                </div>}
                            
            {tripBasicInformation && <img src={tripBasicInformation.largeImageUrl} onLoad={() => setIsImageLoaded(true)} style={{ display: 'none' }}></img>}
            {tripBasicInformation && tripBasicInformation!="Not found" && isImageLoaded && 
                <>
                    {isImageLoaded && 
                    <div style={{
                        backgroundImage:`url("`+tripBasicInformation.largeImageUrl+`")`,
                        // backgroundImage: `url(${trip.imageUrl})`, 
                        width: "100%",
                        height: "300px",
                        // width: "100%", 
                        // height: "100%", 
                        display: "flex", 
                        backgroundSize: "cover",
                        // backgroundSize: "auto",
                        // backgroundSize: "200%,100%", 
                        // // transition: "height 0.5s", 
                        backgroundPosition: "center, center", 
                        borderRadius: "30px"
                        }}>
                            <div style={{height:"100%",width:"50%", borderTopLeftRadius: "30px",borderBottomLeftRadius: "30px", backgroundImage: "linear-gradient(to right, black , rgba(0,0,0,0))"}}>
                                <div style={{margin:"25px", color: "white"}}>
                                    <h1>{tripBasicInformation.type}</h1>
                                    <p>{tripBasicInformation.country}</p>
                                    {/* <p style={{fontSize:"12px"}}>Periode</p> */}
                                    <p>{getDayMonth(tripBasicInformation.dateStart)} - {getDayMonth(tripBasicInformation.dateEnd)}</p>
                                    <p>{getYear(tripBasicInformation.dateStart)}</p>
                                    <h3>€{tripBasicInformation.price}</h3><br></br>
                                    {!isTripCancelled && !isUserSubscribed && !isTripFull && !isTripInPast  && <button className='btn_white' onClick={() => setShowSubmitForm(true)}>Inschrijven</button>}
                                    {!isTripCancelled && isUserSubscribed && <button disabled className='btn_hover'>Ingeschreven</button>}
                                    {!isTripCancelled && !isUserSubscribed && isTripFull && <button disabled  style={{backgroundColor:"red", borderColor:"red"}} className='btn_hover'>Volzet</button>}
                                    {!isTripCancelled && !isUserSubscribed && !isTripFull && isTripInPast && <button disabled='true' className='btn_white' onClick={() => setShowSubmitForm(true)}>Afgelopen</button>}
                                    {isTripCancelled && <button disabled  style={{backgroundColor:"red", borderColor:"red"}} className='btn_hover'>Geanuleerd</button>}
                                </div>
                                

                            </div>


                        </div>}
                    {/* <div className={styles.basic_info_wrapper}>
                        {tripBasicInformation && 
                            <>
                                <div style={{display:"flex", flexDirection: "column", height: "100%"}}>
                                    <h1>{tripBasicInformation.type}</h1>
                                    <p>{tripBasicInformation.country}</p>
                                </div>
                                <div style={{display:"flex", flexDirection: "column", height: "100%"}}>
                                    <h2>€{tripBasicInformation.price}</h2>
                                </div>
                            </>
                        }
                    </div> */}
                
            
                    <div className={styles.trip_info_wrapper}>
                        <div
                            className={styles.trip_info_left}>
                                <div style={{backgroundColor:"rgba(40, 137, 67, 0.80)", borderRadius:"15px", color:"white", paddingBottom:"15px", paddingLeft: "10px"}}>
                                    <h2>Organisatoren</h2>
                                    {tripOrganisers && 
                                        <>
                                            <ul style={{listStyleType: "none",  marginTop:"5px"}}>
                                            {tripOrganisers.map((organiser) => (
                                                <li style={{display:"flex"}}><img src={icon_person_white} style={{marginRight:"10px"}}></img>{organiser.name}</li>
                                            ))}
                                            </ul>
                                        </>
                                    }
                                </div>
                                <div style={{border: "solid 2px", borderColor:"rgba(40, 137, 67, 0.80)", borderRadius:"15px", color:"rgba(40, 137, 67, 0.80)", marginTop:"30px", paddingBottom:"15px", paddingLeft: "10px"}}>
                                    {/* <h2>Deelnemers {tripBasicInformation.currentSubscriptions}/{tripBasicInformation.maxParticipants}</h2> */}
                                    <div style={{display:"flex"}}>
                                        <h2>Deelnemers</h2>
                                        <h2 style={{marginLeft:"auto",paddingRight:"15px"}}>{tripBasicInformation.currentSubscriptions}/{tripBasicInformation.maxParticipants}</h2>
                                    </div>
                                    

                                    <h4 style={{color:"#101010", fontWeight: "normal", paddingLeft:"10px", fontSize:"14px"}}>Minimum deelnemers: {tripBasicInformation.minParticipants}</h4>
                                    {!user && <p style={{color:"black"}}><Link to='/login' style={{paddingLeft:"10px", color:"black"}}>Log in</Link> om ingeschreven deelnemers te zien.</p>}    
                                    {tripSubscriptions && user && 
                                        <>
                                            <ul style={{listStyleType: "none", marginTop:"5px"}}>
                                            {tripSubscriptions.map((sub) => (
                                                <li style={{display:"flex"}}><img src={icon_person_green} style={{marginRight:"10px"}}></img>{sub.name}</li>
                                            ))}
                                            </ul>
                                        </>
                                    }
                                </div>
                                <div style={{backgroundColor:"rgba(40, 137, 67, 0.80)", borderRadius:"15px", color:"white", paddingBottom:"15px", paddingLeft: "10px", marginTop:"30px"}}>
                                    <h2>Deadline inschrijvingen</h2>
                                    <p style={{fontSize:"16px", marginTop:"10px", paddingLeft:"10px"}}>{getDateFormated(tripBasicInformation.subscribeDeadline)}</p>
                                </div>

                        </div>
                        <div
                            className={styles.trip_info_right}>
                                <div>
                                    {tripDetailsInformation && 
                                        <>
                                            {Parser(tripDetailsInformation)}
                                        </>
                                    }
                                </div>
                        </div>
                    </div> 
                    
                    
                    {/* {user && <button onClick={handleSubscribe} className='btn'>Lets go!</button>} */}

                    {/* test image load */}
                    
                </>
            }
            {tripBasicInformation=="Not found" && (
                <h2 >Could not find this trip...</h2>
            )}
            </div>

            
            {showSubmitForm && 
            <div id="myModal" className={styles.modal}>
                <div className={styles.modal_content}>
                    <button onClick={() => {setShowSubmitForm(false)}} className={styles.btn_close}>sluit</button>
                    <br></br>
                    {!user && <p><Link to='/login' style={{paddingLeft:"10px"}}>Log in</Link> om je in te schrijven.</p>}
                    {user && <>
                    <p style={{margin:"15px"}}>Leuk dat je wilt mee komen met deze trip! Er zijn nog enkele vraagjes om je inchrijving af te ronden:</p>
                    <form onSubmit={(e) => handleSubscribe(e)}>
                    <h2>Gegevens</h2>
                    <div style={{display:"flex",flexDirection:"column", width:"70%", marginLeft:"20px", fontSize:"14px"}}>
                        <span>Gsm nummer:</span>
                        <input 
                            type="text"
                            required
                            style={{border: "none", borderBottom:"solid 1px black", color:"grey", marginBottom:"10px"}}
                            onChange={(e) => setPhoneNumber(e.target.value)} 
                            value={phoneNumber} 
                        />
                        <span>Woonplaats (stad):</span>
                        <input 
                            type="text"
                            required
                            onChange={(e) => setAdress(e.target.value)} 
                            style={{border: "none", borderBottom:"solid 1px black",marginBottom:"10px", color:"grey"}}
                            value={adress} 
                        />
                        {/* <span>Onthouw mijn gegevens</span>
                        <input 
                            type="checkbox"
                            required
                            
                            // value={type} 
                        /> */}
                    </div>
                    <h2>Voorkeuren</h2>
                    <div style={{display:"flex",flexDirection:"column", width:"70%", marginLeft:"20px", fontSize:"14px"}}>
                        {preferencesOptions && preferencesOptions.map((pref,index) => (
                            <div key={index} style={{display:"flex"}}>
                            <input 
                                type="checkbox"
                                style={{border: "none", borderBottom:"solid 1px black"}}
                                onChange={handleChange}
                                on
                                value={pref} 
                            />
                            <span>{pref}</span>
                            </div>
                        ))}
                    </div> 
                    <h2>Opties</h2>
                    <div style={{display:"flex",flexDirection:"column", width:"70%", marginLeft:"20px", fontSize:"14px"}}>
                    {options && options.map((opt,index) => (
                            <div key={index} style={{display:"flex"}}>
                            <input 
                                type="checkbox"
                                style={{border: "none", borderBottom:"solid 1px black"}}
                                onChange={handleChangeOptions}
                                on
                                value={opt} 
                            />
                            <span>{opt}</span>
                            
                            </div>
                        ))}
                        <span>Totaal: €{priceVar}</span>
                    </div>
                    <h2>Opmerking</h2>
                    <label style={{display:"flex",flexDirection:"column", marginLeft:"20px", fontSize:"14px"}}>
                        
                        <span>Als er nog extra informatie is die we over jouw moeten weten kan je die hier achterlaten:</span>
                        <textarea 
                            type="text"
                            onChange={(e) => setExtraUserInput(e.target.value)}
                            style={{marginBottom:"30px", height:"50px", resize: "vertical", marginRight: "20px"}}
                            value={extraUserInput} 
                        />
                       
                    </label>
                        <button style={{margin: "auto", width:"300px"}} className='btn' >Lets go!</button> {/*onClick={handleSubscribe*/}
                        </form>
                    </>
                    }
                </div>
                
            </div>
            }   
        </div>
    )
}