import { createContext, useState } from "react";

const NavbarContext = createContext();

function NavbarProvider(props){
    const [navbarDarkMode, setNavbarDarkMode] = useState(true)
    const toggleNavbarDarkMode = () =>(
        setNavbarDarkMode(!navbarDarkMode)
    ) 
    return(
        <NavbarContext.Provider value={{navbarDarkMode,toggleNavbarDarkMode}}>
            {props.children}
        </NavbarContext.Provider>
    )
}

export {NavbarContext,NavbarProvider}