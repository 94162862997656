import { useContext, useEffect } from "react";
import img_landing from "../../assets/landing.jpeg";
import styles from "./Landing.module.css"
import { NavbarContext } from "../../context/NavbarContext";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

import icon_home from "../../assets/icon_home.svg"
import logo from "../../assets/logo.png"

import { useAuthContext } from "../../hooks/useAuthContext";
import { useLogout } from "../../hooks/useLogout";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function Landing(){
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const {navbarDarkMode, toggleNavbarDarkMode} = useContext(NavbarContext)
    const history = useHistory();

    useEffect(() => {
        //toggleNavbarDarkMode();
    },[])
    return(
        <div className={styles.landing_wrapper}>
            <div className={styles.landing_container}>
            {/* <nav className={styles.navbar}>
            <ul>
                <li>
                <img src={logo} style={{width:"80px"}}></img>
                </li>

                <li className={styles.title}><Link to="/">Lets Go Tripping</Link></li>
                
                {!user && (
                <>
                    <li><Link to="/trips">Trips</Link></li>
                    <li><Link to="/login"><button className="btn" >Login</button></Link></li>
                   
                </>
                )}

                {user && (
                <>
                    
                    <li><Link to="/home" style={{display:"flex", alignItems: "center",justifyContent: "center"}}><img src={icon_home} alt="" style={{marginRight:"5px"}}/>Home</Link></li>
                    <li><Link to="/trips">Trips</Link></li>
                    
                    <li>
                    <button className="btn" onClick={
                        () => {
                        logout()
                        history.push({pathname:"/"})}
                        }>Logout</button>
                    </li>
                </>
                )}
            </ul>
        </nav> */}




            {/* <h1>Lets <br/>Go <br/>Tripping</h1> */}
            <h1 style={{fontSize: isBrowser ? "167px":'11vh',lineHeight: isBrowser? "140px":"65px"}} onClick={() => history.push({pathname:"/trips"})}>Lets Go Tripping Lets Go Tripping Lets Go Tripping</h1>
            {/* <button onClick={() => {
                history.push({pathname: '/home'})
                toggleNavbarDarkMode()
            }}
            className={styles.btn_landing}
            ><h2>Lets Go!</h2></button> */}
            
            {/* <h1>Lets Go Tripping</h1>
            <p>
            Stap binnen in de digitale wereld van ontdekking met de avontuurlijke broers Bart en Stef. Dit gedreven duo heeft hun passie voor avontuur en reizen omgezet in een revolutionair online platform. Met een scherp oog voor innovatie hebben Bart en Stef een ruimte gecreëerd waar avonturiers van over de hele wereld samenkomen.
            </p>
            <h3>Wat is het?</h3>
            <p>Hun online platform, doordrenkt met de geest van avontuur, biedt reizigers de mogelijkheid om unieke en op maat gemaakte avontuurlijke reizen te organiseren. Of het nu gaat om het trotseren van bergtoppen, duiken in onontdekte oceanen of het verkennen van culturele schatten, Bart en Stef's platform opent de deuren naar een grenzeloze wereld van mogelijkheden.


        Wat deze broers onderscheidt, is niet alleen hun technologische vernuft, maar ook hun diepe begrip van de behoeften van avontuurlijke zielen. Ze hebben een gemeenschap opgebouwd waar gelijkgestemde reizigers samenkomen, ervaringen delen en samen op zoek gaan naar nieuwe avonturen. Door de kracht van het internet te benutten, hebben Bart en Stef de wereld dichter bij elkaar gebracht, waar afstanden vervagen en avontuurlijke connecties bloeien.
            </p>
            <h3>Voor wie?</h3>
            <p>
            Of je nu een doorgewinterde ontdekkingsreiziger bent of net begint met het verkennen van de wereld, het online platform van Bart en Stef opent de deuren naar een wereld vol avontuur, met de warmte en expertise van twee broers die hun passie delen. Voor degenen die verlangen naar reizen die verder gaan dan het gewone, is dit platform de digitale poort naar onbegrensde avonturen.
            </p>
            <h3>Ervaringen</h3>
            <p>...</p>
            <h3>Uitgelichte trips</h3>
            <p>...</p> */}


            </div>
        </div>
    )
}