import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// pages & components
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Navbar from './components/Navbar'
import Trips from './pages/trips/Trips'
import Trip from './pages/trip/Trip'
import Landing from './pages/landing/Landing'
import NewTrip from './pages/new_trip/NewTrip'
import { useEffect, useState } from 'react'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

//styles
import './index.css'; 
import { projectFirestore } from './firebase/config'

import { TailSpin } from "react-loader-spinner";
import Footer from './components/Footer'
import EditTrip from './pages/edit_trip/EditTrip'

import logo from "./assets/logo.png"

function App() {
  const { authIsReady, user } = useAuthContext()
  const [userHasAcces, setUserHasAcces] = useState(false);
  const [accesCode, setAccesCode] = useState("")
  const [checkedAccesCodeFromStorage, setCheckedAccesCodeFromStorage] = useState(false)
  const [accesCodeMessage, setAccesCodeMessage] = useState("")
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const handleAccesCodeSubmit = (e) => {
    e.preventDefault()
    projectFirestore.collection("acces").doc("9Zxinrg3NcIzxCCtFhVL").get().then(
      (doc) => {
        if(doc.exists){
          if(doc.data().code == accesCode){
            localStorage.setItem('acces-code', accesCode);
            setUserHasAcces(true);
          }
          else{
            setAccesCodeMessage("Whoops! De toegangscode is niet juist.")
          }
        }
      }
    )
    // console.log("user-has-acces", accesCode)
  }

  useEffect(() => {
    const accesCodeFromStorage = localStorage.getItem('acces-code');
    // console.log("acces code form storage", accesCodeFromStorage)
    projectFirestore.collection("acces").doc("9Zxinrg3NcIzxCCtFhVL").get().then(
      (doc) => {
        if(doc.exists){
          if(doc.data().code == accesCodeFromStorage){
            setUserHasAcces(true);
          }
        }
        else{
          // console.log("cant find acces code on db")
          // console.log(doc)
        }
        setCheckedAccesCodeFromStorage(true);
      }
    ).then()
  },[])

  return (
    <>
    {isBrowser && <>
      {!userHasAcces && checkedAccesCodeFromStorage && <div className="start_screen_container">
        <div style={{width:"50%"}}>
          {!isImageLoaded && 
          <>
          <img src="https://firebasestorage.googleapis.com/v0/b/letsgotripping-47a2a.appspot.com/o/trips%2Fhiking.JPG?alt=media&token=75982364-ee83-4c02-b7d3-c1140b328797" onLoad={() => setIsImageLoaded(true)} style={{ display: 'none' }}></img>
          <TailSpin
              height="80"
              width="80"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{justifyContent:"center"}}
              wrapperClass=""
              visible={true}
          />
          </>}
          {isImageLoaded && 
          <>
          <h1>Lets <br/>Go <br/>Tripping</h1>
          <form onSubmit={handleAccesCodeSubmit} style={{border:"solid 1px", borderColor:"white", borderRadius:"35px", margin:"auto", height:"50px", width: "400px", marginTop:"60px", color:"white", alignItems: "center", justifyContent: "center", display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",height:"100%",display:"flex",flexDirection:"row",alignItems: "center", justifyContent: "center", marginLeft:'15px'}}>
                <span>Toegangscode:</span>
                <input style={{backgroundColor:"black", border:"none", borderBottom:"solid 1px", borderColor:"white", color: "white"}}
                    type="text"
                    required
                    onChange={(e) => {
                      setAccesCodeMessage("")
                      setAccesCode(e.target.value)
                    }} 
                    value={accesCode} 
                />
                <button style={{backgroundColor:"white", border:"none", borderTopRightRadius:"35px", borderBottomRightRadius:"35px", color:"black", fontSize:"18px", marginLeft:"15px", height:"100%", width:"100%", right:"0px"}}>Go</button>
          </label>
          
          </form>
          {<p style={{textAlign:"center", marginTop:"20px", fontSize:"14px"}}>{accesCodeMessage}</p>}
          </>}
        </div>
      </div>}
      {userHasAcces &&
      <div className="App">
        {authIsReady && (
          <BrowserRouter>
            <Navbar />
            <Switch>
              <Route exact path="/">
              <Landing />
              </Route>
              <Route exact path="/home">
                {!user && <Redirect to="/login" />}
                {user && <Home />}
              </Route>
              <Route path='/trips/:id'> 
                  <Trip/>
              </Route>
              <Route exact path="/trips">
                <Trips />
              </Route>
              <Route exact path="/new-trip">
                <NewTrip />
              </Route>
              <Route path="/login">
                {user && <Redirect to="/home" />}
                {!user && <Login />}
              </Route>
              <Route path="/signup">
                {user && user.displayName && <Redirect to="/home" />}
                {!user && <Signup />}
              </Route>
              <Route path="/edit-trip/:id">
                <EditTrip/>
              </Route>
            </Switch>
            <Footer />
          </BrowserRouter>
        )}
      </div>}
    </>}
      <MobileView style={{height:"100%", width:"100%"}}>
        <>
        {!userHasAcces && checkedAccesCodeFromStorage && <div className="start_screen_container" style={{backgroundColor:"black", height:"100%"}}>
        <div style={{width:"80%", justifyItems:"center", justifyContent:"center"}}>
          {!isImageLoaded && 
          <>
          <img src={logo} onLoad={() => setIsImageLoaded(true)} style={{ display: 'none' }}></img>
          {/* <img src="https://firebasestorage.googleapis.com/v0/b/letsgotripping-47a2a.appspot.com/o/trips%2Fhiking.JPG?alt=media&token=75982364-ee83-4c02-b7d3-c1140b328797" onLoad={() => setIsImageLoaded(true)} style={{ display: 'none' }}></img> */}
          <TailSpin
              height="80"
              width="80"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{justifyContent:"center"}}
              wrapperClass=""
              visible={true}
          />
          </>}
          {isImageLoaded && 
          <>
          {/* <h1 style={{fontSize:"140px"}}>Lets <br/>Go <br/>Trip</h1> */}
          {/* <h2 style={{textAlign:"center",margin:'0px',padding:"0px",fontSize:"32px",marginBottom:"50px"}}>Lets Go Tripping</h2> */}
          <h2 style={{textAlign:"center", padding:"0px", fontSize:"40px"}}>Lets Go</h2>
          <div style={{display:"flex", alignContent: "center", marginBottom:"20%", flexDirection:"column"}}>
          <img src={logo} style={{width:'60%', display:"block", margin:"auto"}}></img>
          <h2 style={{textAlign:"center", padding:"0px", fontSize:"36px"}}>Tripping</h2>
          </div>  
          
          <span>Toegangscode</span>
          <form onSubmit={handleAccesCodeSubmit} style={{border:"solid 1px", borderColor:"white", borderRadius:"35px", margin:"auto", height:"50px", width: "100%", marginTop:"10px", color:"white", alignItems: "center", justifyContent: "center", display:"flex",flexDirection:"row"}}>
          <label style={{width:"100%",height:"100%",display:"flex",flexDirection:"row",alignItems: "center", justifyContent: "center", marginLeft:'15px'}}>
                <input style={{background:"none", border:"none", borderColor:"white", color: "white", fontSize:'16px'}}
                    type="text"
                    required
                    onChange={(e) => {
                      setAccesCodeMessage("")
                      setAccesCode(e.target.value)
                    }} 
                    value={accesCode} 
                />
                <button style={{backgroundColor:"white", border:"none", borderTopRightRadius:"35px", borderBottomRightRadius:"35px", color:"black", fontSize:"18px", marginLeft:"15px", height:"100%", width:"100%", right:"0px"}}>Go</button>
          </label>
          
          </form>
          {<p style={{textAlign:"center", marginTop:"20px", fontSize:"14px"}}>{accesCodeMessage}</p>}
          </>}
        </div>
      </div>}
      {userHasAcces &&
      <div className="App">
        {authIsReady && (
          <BrowserRouter>
            <Navbar />
            <Switch>
              <Route exact path="/">
              <Landing />
              </Route>
              <Route exact path="/home">
                {!user && <Redirect to="/login" />}
                {user && <Home />}
              </Route>
              <Route path='/trips/:id'> 
                  <Trip/>
              </Route>
              <Route exact path="/trips">
                <Trips />
              </Route>
              <Route exact path="/new-trip">
                <NewTrip />
              </Route>
              <Route path="/login">
                {user && <Redirect to="/home" />}
                {!user && <Login />}
              </Route>
              <Route path="/signup">
                {user && user.displayName && <Redirect to="/home" />}
                {!user && <Signup />}
              </Route>
              <Route path="/edit-trip/:id">
                <EditTrip/>
              </Route>
            </Switch>
            <Footer />
          </BrowserRouter>
        )}
        </div>
        }
        </>
      </MobileView>
    </>
  );
}

export default App