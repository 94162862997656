import { Link } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import { useLogout } from '../hooks/useLogout'

// styles
import styles from './Navbar.module.css'

import logo from "../assets/logo.png"
import icon_home from "../assets/icon_home.svg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

import { NavbarContext } from "../context/NavbarContext"
import { useContext } from "react"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function Navbar() {
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const history = useHistory();
  const {navbarDarkMode, toggleNavbarDarkMode} = useContext(NavbarContext)

  return (
    navbarDarkMode && <nav className={styles.navbar}>
      <ul>
        <li>
          {isBrowser && <img src={logo} style={{width:"80px"}}></img>}
          {!isBrowser && <img onClick={() => history.push({pathname:"/"})} src={logo} style={{width:"60px"}}></img>}
        </li>

        {isBrowser && <li className={styles.title}><Link to="/">Lets Go Tripping</Link></li>}
        {!isBrowser && <li className={styles.title}></li>}
        
        {!user && (
          <>
            <li><Link to="/trips">Trips</Link></li>
            <li><Link to="/login"><button className="btn" >Login</button></Link></li>
            {/* <li><Link to="/signup">Signup</Link></li> */}
          </>
        )}

        {user && (
          <>
            {/* <li><Link to="/home">Home</Link></li> */}
            <li><Link to="/home" style={{display:"flex", alignItems: "center",justifyContent: "center"}}><img src={icon_home} alt="" style={{marginRight:"5px"}}/>Home</Link></li>
            <li><Link to="/trips">Trips</Link></li>
            {/* <li>   _hello, {user.displayName}</li> */}
            <li>
              <button className="btn" onClick={
                () => {
                  logout()
                  history.push({pathname:"/"})}
                }>Logout</button>
            </li>
          </>
        )}
      </ul>
    </nav>
  
  )
}
