import { useState, useEffect } from 'react'
import { projectAuth, provider, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const login = async (email, password) => {
    setError(null)
    setIsPending(true)
  
    try {
      // login
      const res = await projectAuth.signInWithEmailAndPassword(email, password)

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  const loginWithGoogle = async () => {
    setError(null)
    setIsPending(true)

    try{
      const res = await projectAuth.signInWithPopup(provider)
      console.log("res: " + res)
      console.log("get profile : ", res.additionalUserInfo.profile)
      // add user to db

      projectFirestore.collection("users").doc(res.user.uid).onSnapshot((doc) => {
        if(!doc.exists){
          // projectAuth.currentUser.updateProfile({displayName:res.additionalUserInfo.profile.given_name})
          projectFirestore.collection("users").doc(res.user.uid).set({
            firstName: res.additionalUserInfo.profile.given_name, 
            lastName: res.additionalUserInfo.profile.family_name,
            role: "User",
            email: res.user.email
          })
          .then(() => {
            console.log("Document successfully written!");
            dispatch({ type: 'LOGIN', payload: res.user })
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
        }
        else{
          console.log("user allready registered")
          dispatch({ type: 'LOGIN', payload: res.user })
        }
      })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    }
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { login, loginWithGoogle, isPending, error , setError}
}