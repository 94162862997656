import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'

// styles
import styles from './Login.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import icon_google_color from "../../assets/icon_google_color.png"
import icon_google from "../../assets/icon_google.svg"
import { projectAuth } from '../../firebase/config'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, loginWithGoogle, error, isPending, setError } = useLogin()
  const [IsResettingPassword, setIsResttingPassword] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  const handleGoogleLogin = (e) => {
    e.preventDefault()
    loginWithGoogle()
  }

  const handleForgotPassword = () => {
    
    projectAuth.sendPasswordResetEmail(email).catch((e)=> {
      console.log(e)
    })
    setIsResttingPassword(false)
  }

  return (
    <>
    <div style={{display:"flex",justifyItems:"center",alignItems:"center",margin:"auto", justifyContent:"center",width:"100%"}}>
    {isBrowser && <img style={{height:"350px", backgroundSize: "cover", borderRadius:"30px", marginRight:'50px'}} src="https://firebasestorage.googleapis.com/v0/b/letsgotripping-47a2a.appspot.com/o/ae66ea7cc61181d62d0b8d35335409cb.JPEG?alt=media&token=e1e8d3fd-6af8-40c9-9c83-2e0bbd33d540"></img>}

    {/* <form onSubmit={handleSubmit} className={styles['login-form']}>
      <p>No account? <Link to='/signup'>Signup</Link></p>
      <h2>login</h2>
      <label>
        <span>email:</span>
        <input 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
        />
      </label>
      <label>
        <span>password:</span>
        <input 
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password} 
        />
      </label>
      { !isPending && <button className="btn">Login</button> }
      { isPending && <button className="btn" disabled>loading</button> }
      { error && <p>{error}</p> }
      <br></br>
      <br></br>
      <button onClick={handleGoogleLogin} className='btn'>
        <img src={icon_google} alt="" style={{height: "20px", width: "20px", padding: "5 5",marginRight:"10px", marginTop:'2px'}}/>
        Login with Google
      </button>
      <button onClick={handleForgotPassword}>reset password</button>
    </form> */}

    <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:isBrowser ? '400px':'85%',justifyContent:"center"}}>
      {!IsResettingPassword &&
      <>
      <button onClick={handleGoogleLogin} className='btn' style={{borderRadius:"20px", width:"100%"}}>
        <div style={{display:"flex", alignItems:"center", maxWidth:'340px',justifyContent:"center"}}>
          <img src={icon_google_color} alt="" style={{height: "20px", width: "20px", padding: "5 5",marginRight:"10px", marginTop:'2px'}}/>
          <p>Login met Google</p>
        </div>
      </button>
      <div style={{borderBottom:"solid 1px grey", marginTop:'30px', width:"85%"}}></div>    
      <form onSubmit={handleSubmit} onChange={() => setError(null) } style={{display:"flex",flexDirection:"column", width:"100%"}}>
      <input 
        type="email" 
        onChange={(e) => setEmail(e.target.value)} 
        value={email}
        style={{alignSelf:"center",width:isBrowser?'384px':"100%", fontSize:"14px", borderRadius:"5px",border:"solid 1px grey", padding:"7px", marginTop:"29px", marginBottom:"8px"}}
        placeholder="email"
      />
      <input 
        type="password" 
        onChange={(e) => setPassword(e.target.value)} 
        value={password} 
        style={{alignSelf:"center",width:isBrowser?'384px':"100%", fontSize:"14px", borderRadius:"5px",border:"solid 1px grey", padding:"7px"}}
        placeholder="wachtwoord"
      />  
      <button style={{display:"none"}}></button>
      </form> 
      <button style={{fontSize:'13px',marginTop:"8px", alignSelf:"start", border:"none",background:"none", cursor:"pointer"}} onClick={()=>setIsResttingPassword(true)}>Wachtwoord vergeten?</button>
      { !isPending && <button onClick={handleSubmit} style={{width:'100%', borderRadius:"20px", marginTop:"15px"}} className="btn">Login met Email</button> }
      { isPending && <button onClick={handleSubmit} style={{width:'100%', borderRadius:"20px", marginTop:"15px"}} className="btn">loading</button> }

      { error && <p style={{fontSize:'13px',marginTop:"8px", alignSelf:"start"}}>Whoops! De inloggegevens lijken niet te kloppen.</p> }
      <p style={{fontSize:'14px',marginTop:"30px"}}>Nog geen account? <Link style={{color:"black"}} to='/signup'>Signup</Link></p>
      </>}

      { IsResettingPassword && 
      <>
      <h1 style={{fontSize:'22px'}}>Wachtwoord vergeten?</h1>
      <p style={{fontSize:'14px',marginTop:"5px"}}>Geen probleem! We sturen je een mail om je wachtwoord te resetten.</p>
      <input 
        type="email" 
        onChange={(e) => setEmail(e.target.value)} 
        value={email}
        style={{width:'384px', fontSize:"14px", borderRadius:"5px",border:"solid 1px grey", padding:"7px", marginTop:"29px", marginBottom:"8px"}}
        placeholder="email"
      />
      <button style={{width:'100%', borderRadius:"20px", marginTop:"15px"}} className="btn" onClick={()=>{handleForgotPassword()}}>Verzenden</button>
      </>
      }

    </div>


    {/* <form onSubmit={handleSubmit} className={styles['login-form']}>
      
     
      <button onClick={handleGoogleLogin} className='btn'>
      <div style={{display:"flex", alignItems:"center", width:'328px',justifyContent:"center"}}>
        <img src={icon_google_color} alt="" style={{height: "20px", width: "20px", padding: "5 5",marginRight:"10px", marginTop:'2px'}}/>
        <p>Login met Google</p>
        </div>
      </button>
      <div style={{borderBottom:"solid 1px black", marginTop:'40px'}}></div>
      
      
      <label >
        <input 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
          style={{width:'328px'}}
          placeholder="email"
        />
      </label>
      <label>
        <input 
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password} 
          style={{width:'328px', fontSize:"14px"}}
          placeholder="wachtwoord"
        />
      </label>
      { !isPending && <button style={{width:'328px'}} className="btn">Login met Email</button> }
      { isPending && <button className="btn" disabled>loading</button> }
      { error && <p>{error}</p> }
      <br></br>
      <br></br>
      <p>Nog geen account? <Link to='/signup'>Signup</Link></p>
      <button onClick={handleForgotPassword}>reset password</button>
    </form> */}
    </div>
    </>
  )
}
