import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import TripForm from './TripForm'
import { projectFirestore } from '../../firebase/config'
// styles
import styles from './Trips.module.css'

// components
import TripsList from './TripsList'
import { useEffect, useState } from 'react'

import firebase from 'firebase/app'
import "firebase/storage"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function Trips() {
  const { user } = useAuthContext()
  const history = useHistory();
  const { documents, error } = useCollection('trips')
  const [trips, setTrips] = useState([])
  
  useEffect(() => {
    let tripsRef = projectFirestore.collection("trips");
    tripsRef.onSnapshot(snapshot => {
        let results = []
        snapshot.docs.forEach(doc => {
          results.push({...doc.data(), id: doc.id})
        });
      
        // update state
        setTrips(results)
        
        }, error => {
          console.log(error)
        })
  },[])

  return (
    <>
      <div className={styles.trips_wrapper}>
        <div className={styles.trips_container}>
          <h1>Trips</h1>
          <div className={styles.content}>
            {error && <p>{error}</p>}
            {documents && <TripsList trips={trips} />}
          </div>
        </div>
      </div>
    </>
  )
}