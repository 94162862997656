import { useFirestore } from '../../hooks/useFirestore'
import { useHistory } from 'react-router-dom';

import icon_person from "../../assets/icon_person.svg"

// styles
import styles from './Trips.module.css'

import canoeImg from "../../assets/canoe.jpg"
import { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';

export default function TripsList({ trips }) {
  const history = useHistory();
  const [imagesAllLoaded, setImagesAllLoaded] = useState(false)
  const [tripsInPast, setTripsInPast] = useState(false)
  const [tripsInFuture, setTripsInFuture] = useState(false)
  const [noTrips, setNoTrips] = useState(false)


  let imagesLoadedCount = 0

  const getDayMonth = (string_date) => {
    const date = new Date(string_date);
    return date.getDate()+"/"+(date.getMonth()+1)
  }

  const getYear = (string_date) => {
    const date = new Date(string_date);
    return date.getFullYear();
  }

  const getColourBasedOnSubscriptions = (subs, maxSubs) => {
    if(subs/maxSubs>=0.8){
      return "#d17d41"
    }
    else {
      return "#288943"
    }
  }

  const handleImageLoaded = () => {
    // console.log("images loaded", imagesLoadedCount)
   imagesLoadedCount = imagesLoadedCount + 1
    if(imagesLoadedCount == trips.length){
      setImagesAllLoaded(true)
    }
    // console.log("images loaded", imagesLoadedCount)
    // console.log("total images", trips.length)
  }

  const isTripInPast = (trip) => {
    const trip_time = new Date(trip.dateEnd).getTime();
    if(trip_time>Date.now()){
      if(!tripsInFuture){
        setTripsInFuture(true);
      }
      return false;
    }
    else {
      if(!tripsInPast){
        setTripsInPast(true)
      }
      return true;
    }
  }

  useEffect(() => {
    // console.log("trips:",trips)
    if(!trips){
      // console.log("there are no trips")
      // console.log(trips)
      setNoTrips(true)
      setImagesAllLoaded(true)
    }
  },[trips])

  const getDateFormated = (string_date) => {
    const date = new Date(string_date);
    return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
  }



  return (
    // <ul className={styles.transactions}>
      
    //   {trips.map((trip) => (
    //     <div className={styles.li_wrapper}>
    //      <li key={trip.id} onClick={() => {history.push({pathname: '/trips/'+trip.id,trip_overview: trip})}}>
          
    //       <div style={{backgroundImage:`url("`+trip.imageUrl+`")`,backgroundSize: "cover", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: "10px", height:"70%"}}>
    //       <h1 className={styles.name}>{trip.country}</h1>
    //       <p className={styles.name}>€{trip.price}</p>
    //       <p className={styles.name}>{trip.description}</p>
    //       {trip.participants && <p className={styles.name}>{trip.participants.length}/{trip.maxParticipants}</p>}
    //       {!trip.participants && <p className={styles.name}>0/{trip.maxParticipants}</p>}
    //       </div>

    //     </li>
    //     </div>
    //   ))}
    // </ul>
    <>
      {!imagesAllLoaded && 
        <div style={{width:"100%", alignSelf:"center"}}>
          <TailSpin
                          height="80"
                          width="80"
                          color="#000000"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{justifyContent:"center"}}
                          wrapperClass=""
                          visible={true}
          />
        </div>}
        {trips.map((trip,index) => (
        <img key={index} src={trip.imageUrl} style={{ display: 'none' }} onLoad={handleImageLoaded}></img>
      ))}
      {!trips && <p style={{alignSelf:"start", width:"100%"}}>Er konden geen trips gevonden worden.</p>

      }
    {imagesAllLoaded &&  
    <div style={{display:"flex",flexDirection:"column", justifyContent: "center", width:"100%"}}>
    
      {tripsInFuture && <p style={{borderBottom: "solid 0.5px black", width:"95%", alignSelf:"center", fontSize:"14px", marginBottom:"20px"}}>Gepland</p>}
      <div className={styles.content_wrapper}>
        {trips.map((trip,index) => (  !isTripInPast(trip) &&
          <div key={index} className={styles.wrapper} onClick={() => {history.push({pathname: '/trips/'+trip.id,trip_overview: trip})}}>
          <div className={styles.image_wrapper} >
            <div style={{
              backgroundImage:`url("`+trip.imageUrl+`")`,
              // backgroundImage: `url(${trip.imageUrl})`, 
              width: "100%",
              height: "100%",
              // width: "100%", 
              // height: "100%", 
              display: "flex", 
              backgroundRepeat:"no-repeat",
              backgroundSize: "100%, 100%",
              // backgroundSize: "200%,100%", 
              // // transition: "height 0.5s", 
              backgroundPosition: "center, center", 
              borderTopLeftRadius: "10px", 
              borderTopRightRadius: "10px"
              }}>

            
            <div className={styles.price_wrapper}>	
              <div className={styles.price}>
                <span>€{trip.price}</span>
              </div>
            </div>
            <div className={styles.participants_wrapper}>	
              <div style={{backgroundColor:getColourBasedOnSubscriptions(trip.currentSubscriptions,trip.maxParticipants)}} className={styles.participants}>
                <img src={icon_person} alt="" style={{verticalAlign: "top"}}/>
                <span style={{marginLeft:'5px'}}>{trip.currentSubscriptions}/{trip.maxParticipants}</span>
              </div>
            </div>
          </div>
          </div>
          <div className={styles.info_wrapper}>
            <div className={styles.info_wrapper_left}>
              <h1>{trip.type}</h1>
              <h2>{trip.country}</h2>
            </div>
            <div className={styles.info_wrapper_right}>
              <h3 style={{textAlign:"right", paddingRight:"10px"}}>{getDayMonth(trip.dateStart)} - {getDayMonth(trip.dateEnd)}</h3>
              <h3 style={{textAlign:"right", paddingRight:"10px"}}>{getYear(trip.dateStart)}</h3>
            </div>
            <p style={{fontFamily: "sans-serif",fontWeight:"bold"}}>Deadline inschrijvingen {getDateFormated(trip.subscribeDeadline)}</p>
            <p>
              {trip.description}
            </p>

          </div>


        </div>
        ))}
      </div>

      {tripsInPast && <p style={{borderBottom: "solid 0.5px black", width:"95%", alignSelf:"center", fontSize:"14px", marginBottom:"20px"}}>Afgelopen</p>}
      <div className={styles.content_wrapper}>
        {trips.map((trip,index) => (  isTripInPast(trip) &&
          <div key={index} className={styles.wrapper} onClick={() => {history.push({pathname: '/trips/'+trip.id,trip_overview: trip})}}>
          <div className={styles.image_wrapper} >
            <div style={{
              backgroundImage:`url("`+trip.imageUrl+`")`,
              // backgroundImage: `url(${trip.imageUrl})`, 
              width: "100%",
              height: "100%",
              // width: "100%", 
              // height: "100%", 
              display: "flex", 
              backgroundRepeat:"no-repeat",
              backgroundSize: "100%, 100%",
              // backgroundSize: "200%,100%", 
              // // transition: "height 0.5s", 
              backgroundPosition: "center, center", 
              borderTopLeftRadius: "10px", 
              borderTopRightRadius: "10px"
              }}>

            
            <div className={styles.price_wrapper}>	
              <div className={styles.price}>
                <span>€{trip.price}</span>
              </div>
            </div>
            <div className={styles.participants_wrapper}>	
              <div style={{backgroundColor:getColourBasedOnSubscriptions(trip.currentSubscriptions,trip.maxParticipants)}} className={styles.participants}>
                <img src={icon_person} alt="" style={{verticalAlign: "top"}}/>
                <span style={{marginLeft:'5px'}}>{trip.currentSubscriptions}/{trip.maxParticipants}</span>
              </div>
            </div>
          </div>
          </div>
          <div className={styles.info_wrapper}>
            <div className={styles.info_wrapper_left}>
              <h1>{trip.type}</h1>
              <h2>{trip.country}</h2>
            </div>
            <div className={styles.info_wrapper_right}>
              <h3 style={{textAlign:"right", paddingRight:"10px"}}>{getDayMonth(trip.dateStart)} - {getDayMonth(trip.dateEnd)}</h3>
              <h3 style={{textAlign:"right", paddingRight:"10px"}}>{getYear(trip.dateStart)}</h3>
            </div>
            <p>
              {trip.description}
            </p>

          </div>


        </div>
        ))}
      </div>
    </div>}

    </>
  )
}